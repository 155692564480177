import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  data: function data() {
    return {
      imglist: []
    };
  },
  methods: {
    getMyAudit: function getMyAudit() {
      var _this = this;
      var companyId = accountStorage.getCacheUserInfo().loginCompanyId;
      var url = "/pjyy-deepexi-user-center/api/v1/certificate/findByCompanyId?companyId=".concat(companyId);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == "200") {
          // 遍历拿到附件图片
          var list = [];
          res.data.data.forEach(function (item) {
            item.cerResource.length && item.cerResource.forEach(function (it) {
              list.push(it);
            });
          });
          _this.imglist = list;
        }
      });
    }
  },
  mounted: function mounted() {
    this.getMyAudit();
  }
};